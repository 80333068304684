<template>
  <div class="searchWrapper">
    <multiselect
      id="globalSearch"
      ref="searchBox"
      :options="options"
      :multiple="false"
      group-values="libs"
      group-label="category"
      :group-select="false"
      :placeholder="search"
      track-by="title"
      label="title"
      name="globalSearch"
      :internal-search="false"
      :preserve-search="true"
      select-label=""
      class="profile-search-box"
      :show-no-options="true"
      @search-change="submitSearch"
      @select="selectResult"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from '@suadelabs/vue3-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    search: {
      type: String,
      default: 'Search for resources on ApprenticeSearch.com',
    },
  },
  data() {
    return {
      options: [],
      value: [],
    };
  },
  mounted() {
    this.locale = document.getElementById('search').getAttribute('data');
    this.submitSearch();
    this.setText();
  },
  methods: {
    submitSearch(phrase) {
      this.value = phrase;
      if (!phrase) {
        this.options = [];
        return;
      }
      const self = this;
      this.isLoading = true;
      fetch(`/search/1?locale=${this.locale}&q=${phrase}`)
        .then((response) => response.json()) // Assuming the response is in JSON format, adjust accordingly if not
        .then((data) => self.setOptions(data))
        .catch((error) => {
          console.error(error);
          self.isLoading = false;
          self.options = [];
        });
    },
    setOptions(res) {
      this.isLoading = false;
      this.options = [];
      if (this.value == null || this.value === '') {
        return;
      }
      const jobsData = {
        category: this.locale === 'fr' ? 'Emplois' : 'Jobs',
        libs: res.jobs.data,
      };
      const tradesData = {
        category: this.locale === 'fr' ? 'Ressources' : 'Resources',
        libs: res.trades.data,
      };
      const articlesData = {
        category: this.locale === 'fr' ? 'Nouvelles et événements' : 'News & Events',
        libs: res.articles.data,
      };
      if (res.jobs.data.length > 0) {
        this.options.push(jobsData);
      }
      if (res.trades.data.length > 0) {
        this.options.push(tradesData);
      }
      if (res.articles.data.length > 0) {
        this.options.push(articlesData);
      }
    },
    selectResult(res) {
      window.location.href = res.url;
    },
    setText() {
      const multiselectElement = this.$refs.searchBox.$el;
      const resultsContainer = multiselectElement.querySelector('#listbox-globalSearch');
      const results = resultsContainer.querySelectorAll('.multiselect__option');
      results.forEach((element) => {
        if (element.innerHTML === 'List is empty.') {
          element.innerHTML = this.locale === 'fr' ? 'Veuillez saisir une requête de recherche.' : 'Please enter a search query.';
        }
      });
    },
  },
};
</script>
