console.info('Vite ⚡️ Rails');

import '../controllers';
import * as Turbo from '@hotwired/turbo';
Turbo.start();

import { inputValidationListener } from '../components/profiles/input-validation.js';
import { createApp } from 'vue';
import Search from '../components/profiles/search.vue';

function debounce(func, wait, immediate) {
  let timeout;
  return () => {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function resumeSubmitButton() {
  const form = document.getElementById('resume_form');
  const check = document.getElementById('checkbox');

  if (form !== null) {
    if (check == null || check.checked) {
      form.submit();
    } else {
      document.getElementById('error_message').classList.remove('hidden');
    }
  }
}

document.addEventListener('turbo:load', function () {
  globalSearchListener();
  toggleOverflowMenu();
  sortSelect();
  filterForm();
  closeFlashMessage();
  selectMessageBox();
  closeModal();
  checkCookie();
  emailConfirmation();
  searchAutoComplete();
});

const getAttributeById = (id, attribute) => {
  const element = document.getElementById(id);
  return element && element.getAttribute(attribute);
};

document.addEventListener('turbo:load', () => {
  inputValidationListener();

  const mounts = [
    { selector: '#search', component: Search },
    { selector: '#search_box_sidebar', component: Search },
  ];

  const isEnglishLocale = getAttributeById('search', 'data') === 'en' || getAttributeById('search_box_sidebar', 'data') === 'en';

  mounts.forEach(({ selector, component }) => {
    if (component) {
      createApp(Search, {
        search: isEnglishLocale ? 'Search for resources on ApprenticeSearch.com' : 'Rechercher des ressources sur ApprenticeSearch.com',
      }).mount(selector);
    }
  });

  const resumeSubmitButtonElement = document.getElementById('resume_submit_button');
  if (resumeSubmitButtonElement) {
    resumeSubmitButtonElement.addEventListener('click', (event) => {
      event.preventDefault();
      resumeSubmitButton();
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById('profile_resume')) {
    document.getElementById('profile_resume').onchange = function () {
      document.getElementById('resume_form').submit();
    };
  }
});

export function checkCookie() {
  let cookieEnabled = navigator.cookieEnabled;
  if (cookieEnabled) {
    return true;
  } else {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;

    if (!cookieEnabled) {
      return showCookieFail();
    } else {
      document.getElementById('cookieModal').style.display = 'none';
      return true;
    }
  }
}

function showCookieFail() {
  function openModal() {
    document.getElementById('cookieModal').style.display = 'block';
  }
  function closeModal() {
    document.getElementById('cookieModal').style.display = 'none';
  }
  function checkCookie() {
    const cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      openModal();
    }
  }
  window.onload = function () {
    checkCookie();
    document.querySelector('.close-cookie-message').addEventListener('click', closeModal);
  };
  return false;
}

const closeFlashMessage = () => {
  const closeLinks = document.querySelectorAll('.flash_message a#close');
  closeLinks.forEach((link) => {
    link.addEventListener('click', () => {
      const flashMessage = link.closest('.flash_message');
      if (flashMessage) {
        flashMessage.style.display = 'none';
      }
    });
  });
};

export function closeModal(message) {
  if (document.getElementById('modal-close')) {
    const modals = document.querySelectorAll('.modal');
    const closeButton = document.getElementById('modal-close');
    const background = document.getElementById('modal-background');
    const close = () => {
      if (message && message.classList.contains('unread') && document.querySelector('.modal.is-active')) {
        modals.forEach((modal) => {
          modal.classList.remove('is-active');
        });
        setTimeout(() => {
          location.href = location.href;
        }, 10);
      } else {
        modals.forEach((modal) => {
          modal.classList.remove('is-active');
        });
      }
    };
    closeButton.addEventListener('click', close);
    background.addEventListener('click', close);
    document.onkeydown = function (event) {
      event = event || window.event;
      let isEscape = false;
      if ('key' in event) {
        isEscape = event.key === 'Escape' || event.key === 'Esc';
      } else {
        isEscape = event.keyCode === 27;
      }
      if (isEscape) {
        close();
      }
    };
  }
}

const filterForm = () => {
  if (document.forms['filterForm']) {
    const form = document.forms['filterForm'];
    const search = document.getElementById('search');
    const clearFiltersButton = document.getElementById('clearFiltersButton');
    const citySelect = document.getElementById('my_city');
    const formSubmit = debounce(function () {
      if (clearFiltersButton) {
        clearFiltersButton.style.display = 'block';
      }
      document.filterForm.submit();
      form.dispatchEvent(new Event('submit', { bubbles: true }));
    }, 200);
    const clearFilters = () => {
      form.reset();
      form.dispatchEvent(
        new Event('submit', {
          bubbles: true,
        }),
      );
      if (clearFiltersButton) {
        clearFiltersButton.style.display = 'none';
      }
    };
    form.addEventListener('change', formSubmit);
    if (citySelect) {
      citySelect.addEventListener('change', formSubmit);
    }
    if (search) {
      search.addEventListener('enter', formSubmit);
    }
    if (clearFiltersButton) {
      clearFiltersButton.addEventListener('click', clearFilters);
    }
    if (document.getElementById('filterIcon')) {
      document.getElementById('filterIcon').addEventListener('click', function () {
        if (document.getElementById('filter_favourites')) {
          let bool = document.getElementById('filter_favourites').value === 'true'; // convert string to boolean
          bool = !bool; // toggle boolean
          document.getElementById('filter_favourites').value = bool; // set form value
          if (bool === true) {
            // Toggle colour of star to indicate filter on/off
            document.getElementById('filterIcon').childNodes[0].classList.remove('grey-icon');
            document.getElementById('filterIcon').childNodes[0].classList.add('yellow-icon');
          } else {
            document.getElementById('filterIcon').childNodes[0].classList.remove('yellow-icon');
            document.getElementById('filterIcon').childNodes[0].classList.add('grey-icon');
          }
          formSubmit(); // Submit form using debounce function after changes
        }
      });
    }
  }
};

const sortSelect = () => {
  if (document.getElementById('sort')) {
    const sort = document.getElementById('sort');
    sort.addEventListener('change', () => {
      document.forms['sort'].dispatchEvent(new Event('submit', { bubbles: true }));
    });
  }
};

const toggleOverflowMenu = () => {
  const $ellipsis = Array.prototype.slice.call(document.querySelectorAll('.v-ellipsis'), 0);

  // Check if there are any navbar burgers
  if ($ellipsis.length > 0) {
    // Add a click event on each of them
    $ellipsis.forEach((el) => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $target.classList.toggle('is-active');
        el.style.display = 'none';
      });
    });
  }
};

const selectMessageBox = () => {
  const $messageBoxes = Array.prototype.slice.call(document.querySelectorAll('.box.messages'), 0);
  const selectedCount = document.getElementById('selectedCount');
  const selectAll = document.getElementById('selectAll');

  if ($messageBoxes.length > 0) {
    $messageBoxes.forEach((el) => {
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      $target.addEventListener('click', () => {
        el.classList.toggle('selected');
        if (selectedCount !== null) {
          selectedCount.innerHTML = Array.prototype.slice.call(document.querySelectorAll('.selected'), 0).length;
        }
        if (selectAll.checked) {
          selectAll.checked = false;
        }
      });

      selectAll.addEventListener('click', () => {
        $messageBoxes.forEach((el) => {
          const target = el.dataset.target;
          const $target = document.getElementById(target);
          $target.checked = selectAll.checked;
          if ($target.checked) {
            el.classList.add('selected');
          } else {
            el.classList.remove('selected');
          }
        });
        if (selectedCount !== null) {
          selectedCount.innerHTML = Array.prototype.slice.call(document.querySelectorAll('.selected'), 0).length;
        }
      });
    });
  }
};

const globalSearchListener = () => {
  const icon = document.getElementById('globalSearchIcon');
  const searchBar = document.getElementById('globalSearch');
  if (icon !== null) {
    icon.addEventListener('click', () => {
      searchBar.style.display = 'inline-block';
    });
  }
};

const emailConfirmation = () => {
  const myInput = document.getElementById('email_confirmation');
  if (myInput) {
    myInput.onpaste = (e) => e.preventDefault();
  }
};

const searchAutoComplete = () => {
  const form = document.getElementById('trade-search-form');
  if (!form) {
    return;
  }
  const words = JSON.parse(document.getElementById('trades-list').dataset.data);
  const autocomplete = document.getElementById('autocomplete');
  const search = document.getElementById('search_');
  let match = '';
  search.addEventListener('keyup', () => {
    if (search.value.length > 0) {
      const input = search.value;
      autocomplete.innerHTML = input;

      const regex = new RegExp('^' + input + '.*', 'i');

      for (let i = 0; i < words.length; i++) {
        if (words[i].match(regex)) {
          match = words[i];
          autocomplete.innerHTML += words[i].slice(input.length, words[i].length);
          break;
        }
      }
    }
  });
  search.addEventListener('keydown', (e) => {
    if (search.value.length <= 1 && e.key === 'Backspace') {
      autocomplete.innerHTML = '';
    } else if (e.key === 'Tab' && match.length > 0) {
      search.value = match;
      match = '';
      autocomplete.innerHTML = '';
      form.submit();
    }
  });
};
